import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Clean Grip Deadlifts 4×6\\@70% deadlift 1rm`}</p>
    <p>{`then,`}</p>
    <p>{`30-20-10 reps each:`}</p>
    <p>{`Deadlifts (185/125)(rx+ 225/155)`}</p>
    <p>{`Box Jumps (24/20)`}</p>
    <p>{`for time.`}</p>
    <p><em parentName="p">{`Bonus WOD: `}</em></p>
    <p>{`30-Calorie Ski`}</p>
    <p>{`30-Calorie Bike`}</p>
    <p>{`for time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Saturday is our free workout at 9:00 & 10:00am so bring a
friend!`}</em></strong></p>
    <p><strong parentName="p">{`*`}{`This Saturday is also our Rowing clinic at 11:00am. Free for all
Ville members!  Sign up sheet is located by the white board.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      